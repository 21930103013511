<template>
  <div class="row px-md-4">
    <div class="col-12 col-sm-12">
      <h2 class="main-title">Monthly Reports</h2>
    </div>

    <div class="col 12 col-sm-12">
      <div class="card">
        <div class="row">
          <div class="col-12 col-sm-12">
            <grid-loader
              class="expertel-loader"
              color="#ff502d"
              :loading="loading"
              :size="20"
              sizeUnit="px"
            ></grid-loader>
          </div>
        </div>
        <div class="row mt-4">
          <div
            class="col-12 col-sm-12 d-flex flex-column flex-md-row justify-content-end text-center"
          >
            <button
              class="btn-blue-light mt-3 mt-md-0"
              @click="downloadSelectedAccounting()"
              :disabled="selected == null || selected.status !== 'Complete'"
            >
              Download Accounting
            </button>
            <button
              class="btn-blue-light ml-md-3 mt-3 mt-md-0"
              @click="downloadSelectedBills()"
              :disabled="selected == null || selected.status !== 'Complete'"
            >
              Download User Bills
            </button>
            <button
              class="btn-blue-light ml-md-3 mt-3 mt-md-0"
              @click="downloadSelectedReports()"
              :disabled="selected == null || selected.status !== 'Complete'"
            >
              Download PDF Report
            </button>
            <router-link
              :to="'settings/send-reports'"
              class="button-expertel ml-md-3 mt-3 mt-lg-0 rounded"
              >Send Report</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="line-blue-3 mt-4 mb-4"></div>
          </div>
        </div>
        <div class="row" v-if="loading === false">
          <div class="col-12 col-sm-12">
            <div class="table-fixed-header h-600-px">
              <table class="table-2 table-light table-hover table-reports">
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center">Report Date</th>
                    <th class="text-center">Sub Reports</th>
                    <th class="text-center">Status</th>
                    <th class="text-center"># Of Lines</th>
                    <th class="text-center">Total</th>
                    <th class="text-center">Date Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in reports" :key="index">
                    <td class="text-center">
                      <input
                        class="ml-2"
                        type="radio"
                        name="selectDownload"
                        id=""
                        :value="item"
                        v-model="selected"
                      />
                    </td>
                    <td class="text-center">
                      {{ format(item.report_date) }}
                    </td>
                    <td class="text-center">
                      <div v-if="item.has_subreports == 1">
                        <router-link
                          :to="{ name: 'Managers', params: { id: item.id } }"
                          >Manager Report</router-link
                        >
                      </div>
                    </td>
                    <td class="text-center">
                      <div
                        v-if="item.status == 'Complete'"
                        class="color-success"
                      >
                        <strong>
                          <i class="fas fa-check-circle"></i> {{ item.status }}
                        </strong>
                      </div>
                      <div
                        v-else-if="item.status == 'Generating'"
                        class="text-warning"
                      >
                        <strong>
                          <i class="fas fa-clock"></i> {{ item.status }}
                        </strong>
                      </div>
                      <div v-else>
                        <strong>
                          {{ item.status }}
                        </strong>
                      </div>
                    </td>
                    <td class="text-center">{{ item.num_active }}</td>
                    <td class="text-center">
                      <strong>{{ item.total | currencyDolar }}</strong>
                    </td>
                    <td class="text-center">
                      {{ format(item.created, true) }}
                    </td>
                    <td class="text-center">
                      <button
                        class="link color-orange"
                        @click="changeIDReport(item.id)"
                      >
                        <i class="far fa-eye"></i>
                      </button>
                      <button
                        class="link color-orange"
                        @click="regenerateReport(item.id)"
                      >
                        <i class="fas fa-redo"></i>
                      </button>
                      <button
                        class="link color-orange"
                        @click="deleteReport(item.id)"
                      >
                        <i class="far fa-times-circle"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      reports: [],
      loading: true,
      selected: null
    };
  },
  created() {
    const self = this;

    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }

    this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/reports`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(function(response) {
        self.reports = response.data.sort(
          (a, b) => new Date(b.report_date) - new Date(a.report_date)
        );
        self.loading = false;
      })
      .catch(error => this.makeToast("Error", error, "danger"));
  },
  methods: {
    changeIDReport(id) {
      this.$emit("changeIDReport", id);
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    regenerateReport(id) {
      const self = this;
      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      //formData.append("id", id);

      this.axios({
        method: "post",
        url:
          `https://api.expertel.ca/api/v1/client/me/reports/` +
          id +
          "/regenerate",
        responseType: "json",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR", response.data.message, "danger");
            self.loading = false;
          } else {
            self.makeToast("Success", "Report sent to regenerate", "success");
            self.getAllReports();
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    },
    deleteReport(id) {
      const self = this;
      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${id}/delete`,
        responseType: "json",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR", response.data.message, "danger");
            self.loading = false;
          } else {
            self.makeToast(
              "Success",
              "Report was deleted successfully",
              "success"
            );
            self.getAllReports();
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    },
    async getAllReports() {
      const self = this;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.reports = response.data.sort(
            (a, b) => new Date(b.report_date) - new Date(a.report_date)
          );
          self.loading = false;
        })
        .catch(error => this.makeToast("Error", error, "danger"));
    },
    downloadSelectedReports() {
      let link = document.createElement("a");
      link.setAttribute("download", null);
      link.style.display = "none";
      document.body.appendChild(link);
      link.setAttribute("href", this.selected.pdf_url);
      link.click();
      document.body.removeChild(link);
    },
    downloadSelectedAccounting() {
      let link = document.createElement("a");
      link.setAttribute("download", null);
      link.style.display = "none";
      document.body.appendChild(link);
      link.setAttribute("href", this.selected.accounting_url);
      link.click();
      document.body.removeChild(link);
    },
    downloadSelectedBills() {
      let link = document.createElement("a");
      link.setAttribute("download", null);
      link.style.display = "none";
      document.body.appendChild(link);
      link.setAttribute("href", this.selected.user_bills_url);
      link.click();
      document.body.removeChild(link);
    },
    format(input, full = false) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun ",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      let date = input.split("-");
      let month = parseInt(date[1]) - 1;
      let year = date[0];
      let day = "";
      let fullDate = "";
      if (full) {
        day = date[2];
        fullDate = `${day}/${months[month]}/${year}`;
      } else {
        fullDate = `${months[month]}, ${year}`;
      }
      return fullDate;
    }
  }
};
</script>
